import React from 'react';
import AboutUs from './components/AboutUs'; 
import krakenImage from './img/Kraken_na_kole.jpeg'; 
import NavMenu from './components/NavMenu';
// import CategoryMenu from './components/CategoryMenu';
// import MixedComponent from './components/MixedComponent';
// import { imagesAndTexts, products } from './data/MixedComponentData';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  return (
    <div className="App">
      <NavMenu />
      {/* <CategoryMenu />
      <MixedComponent
        imagesAndTexts={imagesAndTexts}
        products={products}
      /> */}

      <AboutUs
        image={krakenImage}
        title="O mně"
        description="Naše cesta začala úplně jednoduše - servisováním kol na koleni v našem obývacím pokoji po dlouhých pracovních dnech. Začalo to tím, že jsem si sám postavil své první kolo. Když se rodinní příslušníci začali ptát, zda bych neudělal totéž pro ně, a později i přátelé, pochopil jsem, že na tom něco bude.

        Pomalu jsem se začal ponořovat do světa servisu a renovace kol s vášní a precizností. Postupem času jsem si uvědomil, že to, co jsem považoval za pouhou zábavu po práci, se může stát mým povoláním. A tak jsme se rozhodli dát tomu šanci naplno.
        
        Dnes tu jsme, abychom vám poskytli ten nejlepší servis, renovace a prodej kol, jaký si zasloužíte. Jsme hrdí na naši cestu a těšíme se, že s vámi můžeme sdílet naši vášeň pro cyklistiku. Ať už jste začátečník nebo zkušený cyklista, jsme tu pro vás. Díky, že jste s námi!"
      />
    </div>
  );
}

export default App;
