import React from 'react';
import { Container, Navbar, Nav, Form, FormControl, Button, NavItem, NavLink } from 'react-bootstrap';
import './NavMenu.css'; // Importujeme vlastní CSS

const NavMenu = () => {
  return (
    <>
      <Navbar bg="light" expand="lg" className="custom-navbar">
        <Container className="custom-container">
          <Navbar.Brand href="#">KoloVečerníček</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <div className="d-flex justify-content-between align-items-center w-100">
              <Form className="d-flex mx-2 search-form align-items-center">
                <FormControl
                  type="search"
                  placeholder="Vyhledávání"
                  className="me-2"
                  aria-label="Vyhledávání"
                />
                <Button variant="outline-custom">Hledat</Button>
              </Form>
              <Nav className="align-items-center custom-nav">
                <div className="pokus d-flex align-items-center">

                  <i className="bi bi-tools icon-large"></i>
                  <div className="ms-3">
                    <Nav.Link href="tel:+420724917081">+ 420 724 917 081</Nav.Link>
                    <Nav.Link href="mailto:servis@kolovecernicek.cz">servis@kolovecernicek.cz</Nav.Link>
                  </div>
                </div>
                <span className="divider"></span>
                <div className="d-flex align-items-center">
                  <i className="bi bi-shop icon-large"></i>
                  <div className="ms-3">
                    <div>E-shop kontakt:</div>
                    <Nav.Link href="mailto:eshop@kolovecernicek.cz">eshop@kolovecernicek.cz</Nav.Link>
                  </div>
                </div>
                <Nav.Link href="#"><i className="bi bi-person icon-large"></i></Nav.Link>
                <Nav.Link href="#"><i className="bi bi-cart icon-large"></i></Nav.Link>
              </Nav>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* Spodní navigační lišta pro mobilní zařízení */}
      <Nav className="fixed-bottom d-lg-none bg-light">
        <NavItem>
          <NavLink href="#"><i className="bi bi-house-door-fill"></i></NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#"><i className="bi bi-search"></i></NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#"><i className="bi bi-person-fill"></i></NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#"><i className="bi bi-cart-fill"></i></NavLink>
        </NavItem>
      </Nav>
    </>
  );
}

export default NavMenu;
