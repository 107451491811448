import React from 'react';
import { Card } from 'react-bootstrap';
import './AboutUs.css'; // Předpokládejme, že zde jsou další vlastní styly

const AboutUs = ({ image, title, description }) => {
  return (
    <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
      <Card className="shadow-lg" style={{ width: '80%', maxWidth: '1000px' }}>
        <Card.Img variant="top" src={image} style={{ maxHeight: '400px', objectFit: 'cover' }} />
        <Card.Body>
          <Card.Title className="text-center">{title}</Card.Title>
          <Card.Text className="text-justify">
            {description}
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};

export default AboutUs;
